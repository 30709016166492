import { Typography, Chip, CircularProgress, Button } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CancelIcon from "@mui/icons-material/Cancel";
import notificationStyle from "./style/notification.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState, NotificationData } from "../../../types";
import { getFirestore, collection, query, orderBy, startAfter, limit, getDocs, QueryDocumentSnapshot, DocumentData, doc, updateDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { updateNotificationData, readNotifications } from "../../../slice/notification-slice";

export const NotificationPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notifications = useSelector((state: AppState) => state.notification.notifications); // Redux notifications
    const sereneUser = useSelector((state: AppState) => state.sereneUser);
    const db = getFirestore();

    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const lastVisible = useRef<QueryDocumentSnapshot<DocumentData> | null>(null);

    const groupByDate = (notifications: NotificationData[]) => {
        const grouped: any = {};
        notifications.forEach((notification) => {
            const date = moment(notification.timestamp).startOf("day");
            const today = moment().startOf("day");
            const yesterday = moment().subtract(1, "days").startOf("day");

            let label;
            if (date.isSame(today)) label = "Today";
            else if (date.isSame(yesterday)) label = "Yesterday";
            else label = date.format("MMM Do, YYYY");

            if (!grouped[label]) grouped[label] = [];
            grouped[label].push(notification);
        });
        return grouped;
    };

    const fetchNotifications = async () => {
        if (!hasMore || loading) return;
        setLoading(true);

        try {
            const notificationsRef = collection(db, `serene_users/${sereneUser.id}/notifications`);
            const q = lastVisible.current
                ? query(notificationsRef, orderBy("timestamp", "desc"), startAfter(lastVisible.current), limit(10))
                : query(notificationsRef, orderBy("timestamp", "desc"), limit(10));

            const snapshot = await getDocs(q);

            if (snapshot.docs.length < 10) setHasMore(false);

            snapshot.forEach((doc) => {
                dispatch(
                    updateNotificationData({
                        id: doc.id,
                        ...doc.data(),
                    })
                );
            });

            if (snapshot.docs.length) lastVisible.current = snapshot.docs[snapshot.docs.length - 1];
        } catch (error) {
            console.error("Error fetching notifications:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            dispatch(readNotifications({}));
            // also set the notifications as read on the database 
            const promises = notifications.map((n) => {
                const notificationsRef = doc(db, `serene_users/${sereneUser.id}/notifications/${n.id}`)
                return updateDoc(notificationsRef, { status: "read" })
            })
            Promise.all(promises)
        };
    }, []); // Mark notifications as read on unmount

    const groupedNotifications = groupByDate(notifications);

    return (
        <div className={notificationStyle.container}>
            <div className={notificationStyle.body}>
                <div className={notificationStyle.header}>
                    <div className={notificationStyle.title}>
                        <NotificationsNoneIcon sx={{ fontSize: "50px", color: "black" }} />
                        <Typography variant="h3" fontWeight={"bolder"}>
                            Notifications
                        </Typography>
                    </div>
                    <div onClick={() => navigate(-1)}>
                        <CancelIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px" }} />
                    </div>
                </div>
                <div className={notificationStyle.notifications}>
                    {Object.keys(groupedNotifications).length === 0 && (
                        <div className={notificationStyle.notification} key={"none"}>
                            <Typography variant="h5" display="flex" alignItems="center">
                                No Notifications
                            </Typography>
                            <Typography mt={"10px"}>You do not have any notifications</Typography>
                        </div>
                    )}
                    {Object.keys(groupedNotifications).map((date, idx) => (
                        <div key={idx}>
                            <Typography variant="h6" fontWeight="bold" mt={2} mb={2}>
                                {date}
                            </Typography>
                            {groupedNotifications[date].map((d: any) => (
                                <div className={notificationStyle.notification} key={d.id}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography variant="h5" display="flex" alignItems="center">
                                            {d.title}
                                            {d.status === "unread" && (
                                                <Chip
                                                    label="Unread"
                                                    color="primary"
                                                    size="small"
                                                    sx={{ marginLeft: "10px", padding: "10px" }}
                                                />
                                            )}
                                        </Typography>
                                        <Typography variant="body2" color={"grey"} fontSize={"14px"}>
                                            {moment(d.timestamp).format("h:mm a")}
                                        </Typography>
                                    </div>
                                    <Typography mt={"10px"}>{d.description}</Typography>
                                </div>
                            ))}
                        </div>
                    ))}
                    {loading && (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <CircularProgress />
                        </div>
                    )}
                    {!loading && hasMore && (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <Button variant="outlined" color="primary" size="large" onClick={fetchNotifications} sx={{
                                borderRadius: "20px", padding: "10px", paddingLeft: "30px", paddingRight: "30px"
                            }}>
                                Load More
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
