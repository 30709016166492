import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Box, CircularProgress } from "@mui/material";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { getFirebaseFirestore } from "../../../const";
import { useSelector } from "react-redux";
import { AppState } from "../../../types";
import moment from "moment";

const BookingGraph = ({ selectedPeriod }: { selectedPeriod: "Week" | "Month" | "Year" }) => {
    const [chartData, setChartData] = useState<{ labels: string[]; data: number[] }>({ labels: [], data: [] });
    const [loading, setLoading] = useState(false);
    const sereneUser = useSelector((state: AppState) => state.sereneUser);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            fetchBookings();
        }, 1000);
    }, [selectedPeriod]);

    const fetchBookings = async () => {
        const now = new Date();
        let startTime: Date;
        let labels: string[] = [];

        // Determine the start time and labels based on the selected period
        if (selectedPeriod === "Week") {
            // Find the Monday of the current week
            const now = new Date();
            const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
            const daysToMonday = (dayOfWeek === 0 ? -6 : 1 - dayOfWeek); // Offset to get to Monday
            const monday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysToMonday);

            startTime = new Date(monday); // Set start time to Monday
            labels = Array.from({ length: 7 }, (_, i) => {
                const date = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + i);
                return date.toLocaleString("default", { weekday: "short" }); // Show weekday names
            });
        } else if (selectedPeriod === "Month") {
            startTime = new Date(now.getFullYear(), now.getMonth(), 1);
            const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
            labels = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
        } else {
            startTime = new Date(now.getFullYear(), 0, 1);
            labels = Array.from({ length: 12 }, (_, i) =>
                new Date(now.getFullYear(), i, 1).toLocaleString("default", { month: "short" })
            );
        }

        // Query Firestore for bookings within the selected period
        const bookingsQuery = query(
            collection(getFirebaseFirestore(), "booking"),
            where("timestamp", ">=", moment(startTime).unix() * 1000),
            where("user", "==", sereneUser.id)
        );

        const snapshot = await getDocs(bookingsQuery);
        const bookings = snapshot.docs.map((doc) => doc.data());

        // Aggregate bookings into a count based on the labels
        const counts = labels.map((label, index) => {
            if (selectedPeriod === "Week") {
                return bookings.filter((booking) => {
                    const bookingDate = moment(booking.timestamp).toDate();
                    return bookingDate.toLocaleString("default", { weekday: "long" }) === label;
                }).length;
            } else if (selectedPeriod === "Month") {
                return bookings.filter((booking) => {
                    const bookingDate = moment(booking.timestamp).toDate();
                    return bookingDate.getDate() === index + 1;
                }).length;
            } else {
                return bookings.filter((booking) => {
                    const bookingDate = moment(booking.timestamp).toDate();
                    return bookingDate.getMonth() === index;
                }).length;
            }
        });

        setChartData({ labels, data: counts });
        setLoading(false);
    };

    return (
        <Box
            sx={{
                width: { md: "50%", sm: "100%", lg: "50%" },
            }}
        >
            <div
                style={{
                    padding: "20px",
                    background: "white",
                    borderRadius: "20px",
                    position: "relative",
                    opacity: loading ? "0.4" : "1",
                    transition: "opacity 0.5s ease",
                }}
            >
                <Line
                    data={{
                        labels: chartData.labels,
                        datasets: [
                            {
                                label: `Number of bookings this ${selectedPeriod.toLowerCase()}`,
                                data: chartData.data,
                                borderColor: "black",
                                borderWidth: 1,
                                backgroundColor: "rgba(75,192,192,0.2)",
                                pointRadius: 0, // Remove circles at data points
                                tension: 0.4, // Make the line smooth
                            },
                        ],
                    }}
                    options={{
                        responsive: true,
                        layout: {
                            padding: {
                                top: 0, // Adds padding below the legend
                                bottom: 0
                            },
                        },
                        plugins: {
                            legend: {
                                display: true,
                            },
                            tooltip: { enabled: true },
                        },
                        scales: {
                            x: {
                                grid: { display: false },
                                ticks: {
                                    padding: 10, // Add padding below the label
                                    color: loading ? "white" : "grey",
                                    callback: (value: any, index: number) => {
                                        if (selectedPeriod === "Month") {
                                            // Display only every 3rd day
                                            return index % 3 === 0 ? chartData.labels[index] : "";
                                        }
                                        return chartData.labels[index];
                                    },
                                    autoSkip: false,
                                    maxRotation: 0,
                                    minRotation: 0,
                                },
                            },
                            y: {
                                grid: { display: true },
                                min: 0,
                                ticks: {
                                    precision: 0,
                                    stepSize: 5,
                                },
                                suggestedMax: 20,
                            },
                        },
                    }}
                />
            </div>
            {/* {loading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <CircularProgress />
                </Box>
            )} */}
        </Box>
    );
};

export default BookingGraph;
