import { createSlice } from '@reduxjs/toolkit'

/**
 * Serene user data
 */
export const sereneUserSlice = createSlice({
    name: 'serene_user',
    initialState: {
        fetched: false,
        id: "",
        displayName: "",
        email: "",
        calenderConnected: false,
        calenderToken: "",
        calenderRefreshToken: "",
        displayPicture: "",
        creationTime: 0,
        userName: "",
        availability: {},
        profileCompleted: false,
        timezone: "",
        about: "",
        expertise: [] as string[],
        languages: [] as string[],
        currency: "",
        pricing: 0
    },
    reducers: {
        updateUser: (state, action) => {
            state.fetched = true
            state.displayName = action.payload["display_name"] ?? ""
            state.id = action.payload["id"] ?? ""
            state.email = action.payload["email"] ?? ""
            state.calenderConnected = action.payload["calender_connected"] ?? false
            state.calenderToken = action.payload["calender_token"] ?? ""
            state.calenderRefreshToken = action.payload["calender_refresh_token"] ?? ""
            state.displayPicture = action.payload["display_picture"] ?? ""
            state.creationTime = action.payload["creation_time"] ?? 0
            state.userName = action.payload["username"] ?? ""
            state.availability = action.payload["availability"] ?? {}
            state.profileCompleted = action.payload["profile_complete"] ?? false
            state.timezone = action.payload["timezone"] ?? false
            state.about = action.payload["about"] ?? "I love using Serene ❤️"
            state.expertise = (action.payload["expertise"] ?? []) as string[]
            state.languages = (action.payload["languages"] ?? []) as string[]
            state.currency = (action.payload["currency"] ?? "")
            state.pricing = (action.payload["pricing"] ?? 0)
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateUser, } = sereneUserSlice.actions

export default sereneUserSlice.reducer

