import React from "react";
import { Modal, Button, Spinner } from "flowbite-react";
import { Typography } from "@mui/material";

const EndRecurring = ({
    show,
    onClose,
    onSubmit,
    endLoading,
}: {
    show: boolean;
    onClose: any;
    onSubmit: any;
    endLoading: boolean;
}) => {
    return (
        <Modal show={show} onClose={onClose}>
            <Modal.Header>
                <Typography variant="h6">
                    End Recurring Session
                </Typography>
            </Modal.Header>
            <Modal.Body>
                <div className={`relative ${!endLoading ? "overflow-hidden" : ""}`}>
                    {endLoading && (
                        <div className="absolute inset-0 flex items-center justify-center bg-opacity-30 z-10">
                            <Spinner size="lg" color="purple" />
                        </div>
                    )}
                    <div
                        className={`${endLoading ? "opacity-10" : "opacity-100"} transition-opacity`}
                    >
                        <div className="space-y-4">
                            <Typography variant="body2" gutterBottom>
                                Are you sure you want to end all recurring sessions? This action
                                cannot be undone.
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                All future sessions in this recurring schedule will be removed from
                                the calendar. Any previous sessions will remain unaffected.
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                If you need to set up a new schedule, you can create a new recurring
                                session at any time from the client details page.
                            </Typography>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSubmit} disabled={endLoading}>
                    End Recurring Sessions
                </Button>
                <Button color="gray" onClick={onClose} disabled={endLoading}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EndRecurring;
