import { useEffect, useState } from "react";
import BookingGraph from "./booking-graph"
import { Box, Chip } from "@mui/material";
import KPICard from "../../../components/kpi-card";
import { getFirebaseFirestore } from "../../../const";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useSelector } from "react-redux";
import { AppState } from "../../../types";

const Insights = () => {
    const [selectedPeriod, setSelectedPeriod] = useState<"Week" | "Month" | "Year">("Week");
    const [clientCount, setClientCount] = useState(0);
    const sereneUser = useSelector((state: AppState) => state.sereneUser);
    const title = `New clients this ${selectedPeriod.toLowerCase()}`

    useEffect(() => {
        getClientsCountForPeriod(sereneUser.id, selectedPeriod).then((n) => {
            setClientCount(n)
        })
    }, [selectedPeriod])


    return <div>
        <Box display={"flex"} gap={"10px"} mt={"20px"}>
            {["Week", "Month", "Year"].map((d: any) => <Chip label={d} onClick={() => setSelectedPeriod(d)}
                variant={selectedPeriod == d ? "filled" : "outlined"}
                sx={{
                    backgroundColor: selectedPeriod == d ? "black" : "default",
                    color: selectedPeriod == d ? "white" : "default",
                    paddingLeft: '10px',
                    paddingRight: "10px"
                }} />)}
        </Box>
        <br />
        <Box ml={"10px"} display={"flex"} flexWrap={"wrap"} alignItems={"center"} gap={"25px"}>
            <BookingGraph selectedPeriod={selectedPeriod} />
            <KPICard number={clientCount} title={title} />
        </Box>
    </div>
}


const getClientsCountForPeriod = async (userId: string, period: string) => {
    const db = getFirebaseFirestore();
    const now = new Date();
    let startTime;

    // Determine the startTime based on the given period
    switch (period) {
        case "Week":
            startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1); // Start of the current week (Monday)
            break;
        case "Month":
            startTime = new Date(now.getFullYear(), now.getMonth(), 1); // Start of the current month
            break;
        case "Year":
            startTime = new Date(now.getFullYear(), 0, 1); // Start of the current year
            break;
        default:
            throw new Error("Invalid period. Valid options are 'week', 'month', or 'year'.");
    }

    // Convert the startTime to a Firestore-compatible timestamp
    const startTimestamp = startTime.getTime();

    try {
        // Reference to the user's client records collection
        const clientRecordsRef = collection(db, `serene_users/${userId}/client_records`);
        // Query to filter documents based on the creation_timestamp
        const clientsQuery = query(
            clientRecordsRef,
            where("creation_timestamp", ">=", startTimestamp)
        );
        // Fetch the documents matching the query
        const querySnapshot = await getDocs(clientsQuery);

        // Return the count of matched documents
        return querySnapshot.size;
    } catch (error) {
        console.error("Error fetching client records: ", error);
        throw error;
    }
};

export default Insights;