import mixpanel from 'mixpanel-browser';

mixpanel.init("d5c506b37f2dbb9aef5917f926a5b3d8", { debug: true, track_pageview: true, persistence: 'localStorage' });

export const getMixpanel = () => {
    return mixpanel
}


// export const setMixpanelUser = () => {
//     mixpanel.
// }