import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const KPICard = ({ number, title }: { number: number, title: string }) => (
    <Card
        elevation={0}
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            alignSelf: "stretch",
            borderRadius: "20px",
            backgroundColor: "white",
        }}
    >
        <CardContent sx={{ textAlign: "center" }}>
            <Typography
                variant="h2"
                component="div"
                sx={{ fontWeight: "bold", color: "#4CAF50" }}
            >
                {number}
            </Typography>
            <br />
            <Typography variant="body1" color="text.secondary">
                {title}
            </Typography>
        </CardContent>
    </Card>
);

export default KPICard;

