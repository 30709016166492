import { useSelector } from "react-redux"
import { AppState, BookingData } from "../../../types"
import calenderStyle from "../style/calender.module.css"
import { Box, Button, Chip, Typography } from "@mui/material"
import { Clipboard } from "flowbite-react"
import moment from "moment"
import { formatTimeDifference } from "../../../helper/time-helper"
import { useNavigate } from "react-router-dom"
import { PastBookings } from "../scheduling/past-bookings"
import { useState } from "react"


export const Calender = ({ }) => {

    const sereneUser = useSelector((state: AppState) => state.sereneUser)
    const calender = useSelector((state: AppState) => state.calender)
    const [selectedLabel, setSelectedLabel] = useState<"Today" | "This Week" | "This Month">("Today")
    const navigate = useNavigate();
    const filteredEvents = filterEventsByDateRange(calender.events, selectedLabel)

    // filter out events scheduled for today
    // const eventsToday = calender.events.filter((data) => {
    //     const currentDay = moment().format("DD-YY-MMMM")
    //     const calenderDate = moment(data.start_time).format("DD-YY-MMMM")
    //     return currentDay == calenderDate
    // })

    // // filter out events which are not scheduled for today
    // const eventsLater = calender.events.filter((data) => {
    //     const currentDay = moment().format("DD-YY-MMMM")
    //     const calenderDate = moment(data.start_time).format("DD-YY-MMMM")
    //     return currentDay != calenderDate
    // })

    const personalPageLink = `https://serene.vybz.health/user/${sereneUser.userName}`

    return (
        <div>
            <Typography variant="h4">Scheduling Page</Typography>
            <Typography variant="body1" color={"grey"} fontSize={14}>Share your personalized link for easy scheduling</Typography>
            <div className={calenderStyle.item_container}>
                <Typography variant="body1" >🔗 <a href={personalPageLink} className={calenderStyle.link}><span>{personalPageLink}  </span></a></Typography>
                {sereneUser.calenderConnected && <div className={calenderStyle.clipboard}><Clipboard.WithIconText valueToCopy={personalPageLink} /></div>}
            </div>
            <br />
            {/* <Typography variant="h4">Today</Typography>
            <Typography variant="body1" color={"grey"} fontSize={14}>View all your scheduled sessions for today</Typography>
            {eventsToday.length == 0 && <div className={calenderStyle.item_container}>
                <Typography variant="body1" color={"grey"} >You have a free schedule today!</Typography>
            </div>}
            {eventsToday.map((event) => {
                console.log(JSON.stringify(event))
                return <div className={calenderStyle.item_container}>
                    <div>
                        <Typography variant="body1">🕐 {moment(event.start_time).format("hh:mm A")}</Typography>
                    </div>
                </div>
            })}
            <br />
            
            <br />
            <br /> */}
            <Typography variant="h4">Upcoming ({filteredEvents.length})</Typography>
            <Typography variant="body1" color={"grey"} fontSize={14}>Stay on top of your upcoming appointments and plan ahead</Typography>
            <Box display={"flex"} gap={"10px"} mt={"10px"} mb={"10px"} flexDirection={"row"}>
                {["Today", "This Week", "This Month"].map((m) => {
                    return <Chip label={m}
                        sx={{
                            backgroundColor: selectedLabel == m ? "black" : "default",
                            color: selectedLabel == m ? "white" : "default",
                            paddingLeft: '10px',
                            paddingRight: "10px"
                        }}
                        //@ts-ignore
                        onClick={() => setSelectedLabel(m)} variant={selectedLabel == m ? "filled" : "outlined"}
                        size="medium" />
                })}
            </Box>
            <Box mt={"20px"}>
                {filteredEvents.length == 0 && <div className={calenderStyle.item_container}>
                    <Typography variant="body1" color={"grey"} >No upcoming sessions!</Typography>
                </div>}
                {filteredEvents.map((event) => {
                    { console.log(event) }

                    const bookingId = event.extendedProperties?.private?.bookingId ?? ""
                    return <div className={calenderStyle.item_container}>
                        <div>
                            <Box display={"flex"} flexDirection={"row"} gap={"10px"} alignItems={"center"} mb={"10px"}>
                                <Typography variant="h5" fontWeight={"bolder"}>{event?.extendedProperties?.private?.name ?? ""} </Typography>
                                {event.recurringEventId && <Chip label="Recurring" variant="outlined" />}
                            </Box>
                            <Typography variant="body2">{moment(event.start.dateTime).format("Do MMM HH:mm A")}</Typography>
                            <Typography variant="body2" fontSize={12} color={"grey"}>{`In ${formatTimeDifference(event.start.dateTime)}`}</Typography>
                        </div>
                        <Box display={"flex"} flexGrow={1} />
                        <Box flexDirection={"column"} display={"flex"} width={"150px"} gap={"5px"} alignSelf={"flex-end"}>
                            <Button variant="outlined" disableElevation
                                sx={{
                                    borderRadius: "20px"
                                }}
                                fullWidth
                                onClick={() => {
                                    window.open(event.hangoutLink, '_blank')?.focus()
                                }}>
                                Join
                            </Button>
                            <Button variant="outlined" color="primary"
                                disableElevation
                                sx={{
                                    borderRadius: "20px"
                                }}
                                fullWidth
                                onClick={() => {
                                    if (event.recurringEventId) {
                                        navigate(`/booking/${event.recurringEventId}?recurring=true`)
                                    } else {
                                        navigate(`/booking/${bookingId}`)
                                    }
                                }}>
                                Modify
                            </Button>
                        </Box>
                    </div>
                })}
            </Box>
            <br />
            <Typography variant="h4">Past Sessions</Typography>
            <PastBookings />
        </div>
    )
}

// Helper function to get the start of the day (00:00:00) for today's date
const startOfDay = (date: Date): Date => {
    const start = new Date(date);
    start.setHours(0, 0, 0, 0); // Set the time to midnight
    return start;
}

// Helper function to get the end of the day (23:59:59) for today's date
const endOfDay = (date: Date): Date => {
    const end = new Date(date);
    end.setHours(23, 59, 59, 999); // Set the time to 23:59:59.999
    return end;
}

// Function to filter events based on the given date range
const filterEventsByDateRange = (events: BookingData[], label: 'Today' | 'This Week' | 'This Month'): BookingData[] => {
    const now = new Date();
    let startRange: Date, endRange: Date;

    // Determine the date range based on the label
    if (label === 'Today') {
        startRange = startOfDay(now);
        endRange = endOfDay(now);
    } else if (label === 'This Week') {
        const firstDayOfWeek = new Date(now);
        firstDayOfWeek.setDate(now.getDate() - now.getDay()); // Set to the first day of the week (Sunday)
        startRange = startOfDay(firstDayOfWeek);
        endRange = new Date(startRange);
        endRange.setDate(startRange.getDate() + 6); // Set to the last day of the week (Saturday)
        endRange = endOfDay(endRange);
    } else if (label === 'This Month') {
        startRange = new Date(now.getFullYear(), now.getMonth(), 1); // First day of the month
        endRange = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the month
        endRange = endOfDay(endRange);
    }

    // Filter the events based on the calculated date range
    return events.filter(event => {
        const eventStartDate = new Date(event.start.dateTime);
        return eventStartDate >= startRange && eventStartDate <= endRange;
    });
}
