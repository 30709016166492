import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Routes
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'


// Import the functions you need from the SDKs you need
import Login from './pages/login';
import { SereneDashboard } from './pages/serene/dashboard';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { USER_COLLECTION, getFirebaseAuth, getFirebaseFirestore } from './const';
import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, setDoc, where } from 'firebase/firestore';
import { updateUser } from './slice/user-slice';
import { updateUser as sereneUserUpdate } from './slice/serene-user';
import { NoteEdit } from './pages/note-edit';
import { ClientHistory } from './pages/serene/client-history';
import { AppState } from './types';
import { updateCalenderData } from './slice/calender-slice';
import { SereneProfile } from './pages/serene-profile';
import { Scheduling } from './pages/serene/scheduling';
import { Onboarding } from './pages/serene/onboarding';
import { getUserBookings, getUserClients } from './helper/user-helper';
import { updateClients } from './slice/client-slice';
import { NotificationPage } from './pages/serene/notification';
import { incrementUnreadNotifications, updateNotificationData } from './slice/notification-slice';
import PageLoading from './components/page-loading';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const About = lazy(() => import("./pages/about"))
const Transcriber = lazy(() => import("./pages/serene/transcriber"))
const PublicSereneUser = lazy(() => import("./pages/serene/public-serene-user"))
const LandingPage = lazy(() => import('./pages/app/landing'));
const PrivacyPolicy = lazy(() => import('./pages/privacy'));
const Serene = lazy(() => import('./pages/serene'));
const Stress = lazy(() => import('./pages/app/stress'));
const ModifyBooking = lazy(() => import('./pages/serene/modify-booking'));

// Function to determine the current subdomain
const getSubdomain = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  return subdomain;
};

/**
 * Create the different routes
 * @returns 
 */
const RootRouter = () => {
  const subdomain = getSubdomain();

  const commonRoutes = [
    { path: "/about", element: <About product={subdomain === "serene" ? "serene" : "app"} /> },
    { path: "/privacy", element: <PrivacyPolicy /> },
    { path: "/login/", element: <Login loginType={subdomain === "serene" ? "serene" : "app"} /> },
  ];

  const appRoutes = [
    { path: "/", element: <LandingPage /> },
    { path: "/stress", element: <Stress /> },
    { path: "/building", element: <div>Page Under Construction!!</div> },
  ];

  const sereneRoutes = [
    { path: "/setup-scheduling", element: <Scheduling /> },
    { path: "/notification/", element: <NotificationPage /> },
    { path: "/serene-browser-copilot/", element: <Transcriber /> },
    { path: "/", element: <Serene /> },
    { path: "/dashboard", element: <SereneDashboard /> },
    { path: "/note/:id", element: <NoteEdit /> },
    { path: "/client-details/:clientid", element: <ClientHistory /> },
    { path: "/profile", element: <SereneProfile /> },
    { path: "/onboard", element: <Onboarding /> },
    { path: "/user/:userName", element: <PublicSereneUser /> },
    { path: "/booking/:bookingId", element: <ModifyBooking /> },
  ];

  const routes = subdomain === "serene" ? [...commonRoutes, ...sereneRoutes] : [...commonRoutes, ...appRoutes];

  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Suspense>
  );
};




//const analytics = getAnalytics(app);
const routes = createRoutesFromElements(
  //@ts-ignore
  <Route path="/*" element={<RootRouter />} />
);

const router = createBrowserRouter(routes);

const App = () => {

  const [user, loadingAuth, error] = useAuthState(getFirebaseAuth());
  const [calenderFetched, setCalenderFetched] = useState<string | null>(null);
  const sereneUser = useSelector((state: AppState) => state.sereneUser)
  const dispatch = useDispatch()
  // fetch calender details once the user is updated 
  useEffect(() => {
    let unsubscribeNotification: any = null;
    let firstFetch = true

    const fetchCalenderDetails = async () => {
      setCalenderFetched("started")
      const events = await getUserBookings()
      console.log(events)
      dispatch(updateCalenderData(events))
      setCalenderFetched("complete")
    }

    const fetchClients = async () => {
      const clients = await getUserClients(user?.uid ?? "")
      console.log(clients)
      dispatch(updateClients(clients))
    }

    const fetchNotifications = async () => {
      const db = getFirebaseFirestore()
      const notificationsRef = collection(db, `serene_users/${sereneUser.id}/notifications`);

      try {
        // 1. Fetch all unread notifications
        const unreadQuery = query(notificationsRef, where("status", "==", "unread"));
        const unreadSnapshot = await getDocs(unreadQuery);
        const unreadNotifications = unreadSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // 2. If unread notifications are less than 20, fetch additional notifications ordered by timestamp
        let allNotifications = [...unreadNotifications];
        if (unreadNotifications.length < 20) {
          const additionalLimit = 20 - unreadNotifications.length;
          const additionalQuery = query(notificationsRef, orderBy("timestamp", "desc"), limit(additionalLimit));
          const additionalSnapshot = await getDocs(additionalQuery);
          const additionalNotifications = additionalSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

          // increment the notifications
          dispatch(incrementUnreadNotifications(unreadNotifications.length))

          // Merge, ensuring no duplicates
          allNotifications = [
            ...unreadNotifications,
            ...additionalNotifications.filter(
              (n) => !unreadNotifications.some((u) => u.id === n.id)
            ),
          ];
        }

        dispatch(updateNotificationData(allNotifications))

        // 3. Add a snapshot listener for the last unread notification
        const lastUnreadQuery = query(notificationsRef, where("status", "==", "unread"), orderBy("timestamp", "desc"), limit(1));
        unsubscribeNotification = onSnapshot(lastUnreadQuery, (snapshot) => {
          if (!firstFetch) {
            firstFetch = false
            dispatch(incrementUnreadNotifications(1))
          }
          snapshot.forEach((doc) => {
            dispatch(updateNotificationData({
              id: doc.id,
              ...doc.data()
            }))
          });
        });

      } catch (error) {
        console.error("Error fetching notifications:", error);
        throw error;
      }
    };

    if (sereneUser.fetched && !calenderFetched) {
      const isCalnederConnected = sereneUser.calenderConnected
      if (isCalnederConnected) {
        fetchCalenderDetails();
      } else {
        dispatch(updateCalenderData({}))
        setCalenderFetched("complete")
      }
    }

    // methods that should run when user is fetched
    if (sereneUser.fetched) {
      fetchClients()
      fetchNotifications()
    }

    return () => {
      if (unsubscribeNotification) {
        unsubscribeNotification();
      }
    }
  }, [sereneUser])


  useEffect(() => {
    // set up the listeners here
    if (user) {
      const unsubscribeUser = setUpUserListener(user.uid, dispatch);
      const unsubscribeSereneUser = setUpSereneUserListener(user.uid, dispatch)
      return () => {
        unsubscribeUser()
        unsubscribeSereneUser()
      }
    }
  }, [user])

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

/**
 * 
 * @param uid 
 * @param dispatch 
 * @returns 
 */
const setUpUserListener = (uid: string, dispatch: any) => {

  const userDocRef = doc(getFirebaseFirestore(), USER_COLLECTION, uid);

  // Set up the listener for real-time updates
  const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      dispatch(updateUser(docSnapshot.data()));
    } else {
      dispatch(updateUser({ display_name: "", id: uid }));
    }
  }, (error) => {
    console.log("Error fetching document: ", error);
  });

  return unsubscribe;
}

/**
 * 
 * @param uid the user id
 * @param dispatch the store dispatch function
 * @returns 
 */
const setUpSereneUserListener = (uid: string, dispatch: any) => {

  const userDocRef = doc(getFirebaseFirestore(), "serene_users", uid);


  // Set up the listener for real-time updates
  const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      dispatch(sereneUserUpdate(docSnapshot.data()));
    }
  }, (error) => {
    console.log("Error fetching document: ", error);
  });

  return unsubscribe;
}


export default App;