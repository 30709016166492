import React, { useState } from "react";
import {
    Box,
    Typography,
    Button,
    Snackbar,
    Alert,
    Chip,
    MenuItem,
    Checkbox,
    ListItemText,
    TextField,
} from "@mui/material";
import { updateSereneUserData } from "../../../helper/user-helper";
import { TextInput, Select } from "flowbite-react";
import { EXPERTISE_OPTIONS } from "../../../const";
import currencyCodes from "currency-codes"; // Import available currencies

export const ProfessionalDetails = ({ userId, onSubmit }: { userId: string; onSubmit: any }) => {
    const [expertise, setExpertise] = useState<string[]>([]); // Track selected areas of expertise
    const [organization, setOrganization] = useState(""); // Optional field for organization
    const [yearsExperience, setYearsExperience] = useState(0); // Optional field for years of experience
    const [pricing, setPricing] = useState<number | "">(""); // Pricing value for session
    const [currency, setCurrency] = useState(""); // Default currency is now empty
    const [toastMessage, setToastMessage] = useState(""); // Toast message content
    const [openToast, setOpenToast] = useState(false); // To toggle toast visibility

    const handleExpertiseChange = (event: any) => {
        const { value } = event.target;
        setExpertise(value);
    };

    const handleDeleteChip = (chipToDelete: string) => {
        setExpertise(expertise.filter((item) => item !== chipToDelete)); // Remove expertise chip
    };

    const handleSubmit = () => {
        if (expertise.length === 0) {
            setToastMessage("Please select at least one area of expertise.");
            setOpenToast(true);
            return;
        }

        if (!pricing || !currency) {
            setToastMessage("Please fill in the session pricing and select a currency.");
            setOpenToast(true);
            return;
        }

        // Save to firebase
        updateSereneUserData(userId, {
            expertise,
            organization,
            experience: yearsExperience,
            pricing,
            currency,
        });
        onSubmit();
    };

    const handleCloseToast = () => {
        setOpenToast(false);
    };

    return (
        <>
            <Typography variant="h6">Expertise*</Typography>
            <Box mt={1}>
                {/* Expertise Select */}
                <TextField
                    select
                    fullWidth
                    value={expertise}
                    onChange={handleExpertiseChange}
                    SelectProps={{
                        multiple: true,
                        //@ts-ignore
                        renderValue: (selected: string[]) => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} onDelete={() => handleDeleteChip(value)} />
                                ))}
                            </Box>
                        ),
                    }}
                >
                    {EXPERTISE_OPTIONS.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox checked={expertise.indexOf(option) > -1} />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </TextField>
            </Box>

            <Box mt={2}>
                <Typography variant="h6">Organization (Optional)</Typography>
                <TextInput
                    placeholder="Organization"
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                    className="mt-2 w-full"
                />
            </Box>

            <Box mt={2}>
                <Typography variant="h6">How long have you been practicing? (Optional)</Typography>
                <TextInput
                    type="number"
                    value={yearsExperience}
                    onChange={(e) => {
                        const years = parseInt(e.target.value);
                        if (years >= 0) {
                            setYearsExperience(years);
                        }
                    }}
                    className="mt-2 w-full"
                />
            </Box>

            <Box mt={2}>
                <Typography variant="h6">Session Pricing*</Typography>
                <Box display="flex" gap={2} alignItems="center" mt={1}>
                    {/* Currency Dropdown */}
                    <Select
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                        required
                    >
                        {currencyCodes.data.map((cur) => (
                            <option key={cur.code} value={cur.code}>
                                {cur.code} - {cur.currency}
                            </option>
                        ))}
                    </Select>

                    {/* Pricing Input */}
                    <TextInput
                        type="number"
                        placeholder="Enter amount"
                        value={pricing}
                        onChange={(e) => {
                            const price = parseFloat(e.target.value);
                            if (!isNaN(price) && price >= 0) {
                                setPricing(price);
                            } else {
                                setPricing("");
                            }
                        }}
                        className="w-full"
                        required
                    />
                </Box>
            </Box>

            <Box mt={4} display="flex" justifyContent="flex-end">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ paddingRight: "50px", paddingLeft: "50px", borderRadius: "30px" }}
                >
                    Next
                </Button>
            </Box>

            {/* Toast Notification */}
            <Snackbar
                open={openToast}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={handleCloseToast} severity="error" sx={{ width: "100%" }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ProfessionalDetails;
