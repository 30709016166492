import React, { useState } from "react";
import { Modal, Button, Select, TextInput, Spinner } from "flowbite-react";
import { CircularProgress, Typography } from "@mui/material";
import { Datepicker } from "flowbite-react"; // Import Flowbite Datepicker

const RecurringSessionDialog = ({
    show,
    onClose,
    onSubmit,
    recurrenceLoading
}: {
    show: boolean;
    onClose: any;
    onSubmit: any;
    recurrenceLoading: boolean
}) => {
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState("");
    const [duration, setDuration] = useState("30"); // Default to 30 minutes
    const [frequency, setFrequency] = useState("once-a-week");
    const [endDate, setEndDate] = useState(new Date());
    const [errors, setErrors] = useState({
        startDate: false,
        startTime: false,
        endDate: false,
    });

    const calculateEndTime = () => {
        if (!startTime || !duration) return "";

        const [hours, minutes] = startTime.split(":").map(Number);
        const durationInMinutes = parseInt(duration, 10);

        const endTime = new Date();
        endTime.setHours(hours, minutes + durationInMinutes);

        const formattedEndTime = endTime
            .toLocaleTimeString("en-US", { hour12: false })
            .slice(0, 5);

        return formattedEndTime;
    };

    const validateFields = () => {
        const newErrors = {
            startDate: !startDate,
            startTime: !startTime,
            endDate: !endDate,
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some((error) => error);
    };

    const handleSubmit = () => {
        if (validateFields()) {
            const endTime = calculateEndTime();
            const sessionData = {
                startDate,
                startTime,
                endTime,
                frequency,
                endDate,
            };
            onSubmit(sessionData);
        }
    };

    return (
        <Modal show={show} onClose={onClose}>
            <Modal.Header>
                <Typography variant="h6" gutterBottom>Create Recurring Session</Typography>
                <Typography variant="body2">
                    Schedule recurring client sessions by setting a start date, time range, frequency, and end date—all tailored to your needs.
                </Typography>
            </Modal.Header>
            <Modal.Body>
                <div className={`relative ${!recurrenceLoading ? "overflow-hidden" : ""}`}>
                    {recurrenceLoading && (
                        <div className="absolute inset-0 flex items-center justify-center bg-opacity-30 z-10">
                            <Spinner size="lg" color="purple" />
                        </div>
                    )}
                    <div className={`${recurrenceLoading ? "opacity-10" : "opacity-100"} transition-opacity`}>
                        <div className="space-y-4">
                            {/* Start Date */}
                            <div>
                                <Typography variant="body2" gutterBottom>
                                    When will the session start?
                                </Typography>
                                <Datepicker
                                    value={new Date(startDate)}
                                    onChange={(value) => setStartDate(value ?? new Date())}
                                    required
                                />
                                {errors.startDate && (
                                    <Typography variant="caption" color="error">
                                        Start date is required.
                                    </Typography>
                                )}
                            </div>
                            {/* End Date */}
                            <div>
                                <Typography variant="body2" gutterBottom>
                                    When will the recurring sessions end?
                                </Typography>
                                <Datepicker
                                    value={new Date(endDate)}
                                    onChange={(value) => setEndDate(value ?? new Date())}
                                    required
                                />
                                {errors.endDate && (
                                    <Typography variant="caption" color="error">
                                        End date is required.
                                    </Typography>
                                )}
                            </div>

                            {/* Start Time */}
                            <div>
                                <Typography variant="body2" gutterBottom>
                                    Time
                                </Typography>
                                <TextInput
                                    id="startTime"
                                    type="time"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                    required
                                />
                                {errors.startTime && (
                                    <Typography variant="caption" color="error">
                                        Start time is required.
                                    </Typography>
                                )}
                            </div>

                            {/* Duration */}
                            <div>
                                <Typography variant="body2" gutterBottom>
                                    Session Duration
                                </Typography>
                                <Select
                                    id="duration"
                                    value={duration}
                                    onChange={(e) => setDuration(e.target.value)}
                                    required
                                >
                                    <option value="30">30 Minutes</option>
                                    <option value="60">1 Hour</option>
                                    <option value="90">1.5 Hours</option>
                                </Select>
                            </div>

                            {/* Frequency */}
                            <div>
                                <Typography variant="body2" gutterBottom>
                                    Frequency of sessions
                                </Typography>
                                <Select
                                    id="frequency"
                                    value={frequency}
                                    onChange={(e) => setFrequency(e.target.value)}
                                    required
                                >
                                    <option value="once-a-week">Once a Week</option>
                                    <option value="every-two-weeks">Every Two Weeks</option>
                                    <option value="once-a-month">Once a Month</option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleSubmit} disabled={recurrenceLoading}>Create Session</Button>
                <Button color="gray" onClick={onClose} disabled={recurrenceLoading}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RecurringSessionDialog;
